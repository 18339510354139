import React, { useEffect, useState } from 'react';
import { Image, TemplateText } from '../../atoms';
import styles from './footer.module.scss';
import cx from 'classnames';
import Link from 'next/link';
import { MainRouter } from '@config/urls';
import ManvertIcon from '@assets/icons/manvert-logo-black.svg';
import { i18n, useTranslation } from 'next-i18next';
import { SocialLinks } from '@config/social-links';
import { PaisesDropdown } from '../paises-dropdown';
import Cookies from 'js-cookie';
import { LanguageDropdown } from '../language-dropdown';
import { settings } from '@config/settings';
import { paisesDropdown } from '@utils/PaisesDropdown';

export const Footer = (): JSX.Element => {
  const { t } = useTranslation();
  const paises = paisesDropdown;
  const [familyFilter, setFamilyFilter] = useState<any>();
  const [country, setCountry] = useState('es');
  const language = i18n?.language;
  useEffect(() => {
    const cookie = Cookies.get(settings.countryCookie);
    if (cookie) {
      if (language === 'esp') {
        setFamilyFilter(
          paises
            ? paises.find((x) => cookie.toUpperCase() === x.pk)?.fields.nombre
                .es_ES || null
            : null
        );
      } else {
        setFamilyFilter(
          paises
            ? paises.find((x) => cookie.toUpperCase() === x.pk)?.fields.nombre
                .en_US || null
            : null
        );
      }
      setCountry(cookie);
    } else {
      if (language === 'esp') {
        setFamilyFilter(
          paises
            ? paises.find(
                (x) => settings.DEFAULT_COUNTRY.toLocaleUpperCase() === x.pk
              )?.fields.nombre.es_ES || null
            : null
        );
      } else {
        setFamilyFilter(
          paises
            ? paises.find(
                (x) => settings.DEFAULT_COUNTRY.toLocaleUpperCase() === x.pk
              )?.fields.nombre.en_US || null
            : null
        );
      }
    }
  }, [paises, language]);
  return (
    <div className={styles.root}>
      <div className={styles.data}>
        <Link legacyBehavior passHref href={MainRouter.home.generatePath()}>
          <a className={styles.info}>
            <ManvertIcon />
            <TemplateText template="micro" className={cx('micro')}>
              Sustainable Agro Solutions, S.A.U.
            </TemplateText>
            <TemplateText template="micro" className={cx('micro')}>
              Ctra. N-240 Km. 110
            </TemplateText>
            <TemplateText template="micro" className={cx('micro')}>
              25100 Almacelles - Lleida – Spain
            </TemplateText>
            <TemplateText template="micro" className={cx('micro')}>
              +34 973 190 707
            </TemplateText>
          </a>
        </Link>

        <div className={styles.urls}>
          <Link
            legacyBehavior
            passHref
            href={{
              pathname: `/${country}${MainRouter.productos.generatePath()}`,
            }}
          >
            <a>
              <TemplateText template="merry">
                {t('Footer.links.productos')}
              </TemplateText>
            </a>
          </Link>
          <Link
            legacyBehavior
            passHref
            href={{
              pathname: `/${country}${MainRouter.ensayos.generatePath()}`,
            }}
          >
            <a>
              <TemplateText template="merry">
                {t('Footer.links.ensayos')}
              </TemplateText>
            </a>
          </Link>
          <Link legacyBehavior passHref href={MainRouter.medios.generatePath()}>
            <a>
              <TemplateText template="merry">
                {t('Footer.links.medios')}
              </TemplateText>
            </a>
          </Link>
          <Link
            legacyBehavior
            passHref
            href={{
              pathname: `/${country}${MainRouter.soluciones.generatePath()}`,
            }}
          >
            <a>
              <TemplateText template="merry">
                {t('Footer.links.soluciones')}
              </TemplateText>
            </a>
          </Link>
          <Link
            legacyBehavior
            passHref
            href={MainRouter.nosotros.generatePath()}
          >
            <a>
              <TemplateText template="merry">
                {t('Footer.links.nosotros')}
              </TemplateText>
            </a>
          </Link>
          <Link
            legacyBehavior
            passHref
            href={MainRouter['hazte-distribuidor'].generatePath()}
          >
            <a>
              <TemplateText template="merry">
                {t('Footer.links.distribuidor')}
              </TemplateText>
            </a>
          </Link>
          <Link
            legacyBehavior
            passHref
            href={
              'https://canalinformacion.es/?token=90202d5f-8931-11ee-9563-ac1f6b9355aa'
            }
          >
            <a>
              <TemplateText template="merry">
                {t('urls.canalEtico')}
              </TemplateText>
            </a>
          </Link>
          {/* <Link
            legacyBehavior
            passHref
            href={MainRouter.compromiso.generatePath()}
          >
            <a>
              <TemplateText template="merry">
                {t('urls.compromiso')}{' '}
              </TemplateText>
            </a>
          </Link> */}

          <div className={styles.languageAndCountry}>
            <div className={styles.languages}>
              <LanguageDropdown />
            </div>
            <div className={styles.paises}>
              <PaisesDropdown
                familia={familyFilter}
                onChange={(newValue: any) => {
                  setFamilyFilter(newValue);
                }}
                card
              />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.bottomRow}>
        <div className={styles.politica}>
          <Link
            legacyBehavior
            passHref
            href={MainRouter.privacidad.generatePath()}
          >
            <a>
              <TemplateText template="micro">
                {t('Footer.links.privacidad')}
              </TemplateText>
            </a>
          </Link>
          <Link
            legacyBehavior
            passHref
            href={MainRouter.cookies.generatePath()}
          >
            <a>
              <TemplateText template="micro">
                {t('Footer.links.cookies')}
              </TemplateText>
            </a>
          </Link>
          <Link legacyBehavior passHref href={MainRouter.legal.generatePath()}>
            <a>
              <TemplateText template="micro">
                {t('Footer.links.legal')}
              </TemplateText>
            </a>
          </Link>
        </div>
        <div className={styles.social}>
          <a href={SocialLinks.facebook}>
            <Image src={'/static/images/facebook-outlined.svg'} alt="" />
          </a>
          <a href={SocialLinks.instagram}>
            <Image src={'/static/images/instagram.svg'} alt="" />
          </a>
          <a href={SocialLinks.linkedin}>
            <Image src={'/static/images/linkedin-outlined.svg'} alt="" />
          </a>
          <a href={SocialLinks.youtube}>
            <Image src={'/static/images/youtube.svg'} alt="" />
          </a>
        </div>
      </div>
    </div>
  );
};
