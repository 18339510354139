import React, { FormEvent, useState } from 'react';
import classNames from 'classnames';
import { useTranslation, Trans } from 'next-i18next';
import BananoIcon from '@assets/icons/platan.svg';
import BerriesIcon from '@assets/icons/berries.svg';
import CafeIcon from '@assets/icons/cafe.svg';
import CitricosIcon from '@assets/icons/citrico.svg';
import ExtensivoIcon from '@assets/icons/extensivo.svg';
import FloresIcon from '@assets/icons/flores.svg';
import FrutasIcon from '@assets/icons/apple.svg';
import FrutosSecosIcon from '@assets/icons/frutoseco.svg';
import HortalizaIcon from '@assets/icons/hortaliza.svg';
import OlivoIcon from '@assets/icons/olivo.svg';
import PatataIcon from '@assets/icons/patata.svg';
import Uva from '@assets/icons/uvas.svg';
import Fibra from '@assets/icons/fibra.svg';
import styles from './contact-form.module.scss';
import { ColorSet } from '@styles/style-types';
import { Image, TemplateText, Text } from '../../atoms';
import { settings } from '@config/settings';
import { MainRouter } from '@config/urls';
import es from '../../../../assets/locales/esp/common.json';
import en from '../../../../assets/locales/en/common.json';
import { i18n } from 'next-i18next';
import Link from 'next/link';

interface ContactFormProps {
  distribuidor?: any;
  card?: boolean;
  title?: string;
  header?: string;
  empresa?: boolean;
  cultivos?: boolean;
  actividad?: boolean;
  provincia?: boolean;
  telefono?: boolean;
  ciudad?: boolean;
  comentarios?: boolean;
  documentos?: boolean;
  link?: string;
  reducedCargosList?: boolean;
  infoComercialOblig?: boolean;
  empresaOblig?: boolean;
  GDPRTextVersion: 'contacto' | 'distribuidor' | 'croptology' | 'producto';
  eventLabel?: string;
  eventAction?: string;
  croptology?: boolean;
}

const paises = [
  { value: 'Afganistan', es: 'Afghanistán', en: 'Afganistan' },
  { value: 'Albania', es: 'Albania', en: 'Albania' },
  { value: 'Germany', es: 'Alemania', en: 'Germany' },
  { value: 'Algeria', es: 'Algeria', en: 'Algeria' },
  { value: 'Andorra', es: 'Andorra', en: 'Andorra' },
  { value: 'Angola', es: 'Angola', en: 'Angola' },
  { value: 'Anguilla', es: 'Anguilla', en: 'Anguilla' },
  {
    value: 'Antigua & Barbuda',
    es: 'Antigua y Barbuda',
    en: 'Antigua & Barbuda',
  },
  {
    value: 'Netherland Antilles',
    es: 'Antillas Neerlandesas',
    en: 'Netherland Antilles',
  },
  { value: 'Saudi Arabia', es: 'Arabia Saudita', en: 'Saudi Arabia' },
  { value: 'Argentina', es: 'Argentina', en: 'Argentina' },
  { value: 'Armenia', es: 'Armenia', en: 'Armenia' },
  { value: 'Aruba', es: 'Aruba', en: 'Aruba' },
  { value: 'Australia', es: 'Australia', en: 'Australia' },
  { value: 'Austria', es: 'Austria', en: 'Austria' },
  { value: 'Azerbaijan', es: 'Azerbaiján', en: 'Azerbaijan' },
  { value: 'Bahamas', es: 'Bahamas', en: 'Bahamas' },
  { value: 'Bahrain', es: 'Bahrein', en: 'Bahrain' },
  { value: 'Bangladesh', es: 'Bangladesh', en: 'Bangladesh' },
  { value: 'Barbados', es: 'Barbados', en: 'Barbados' },
  { value: 'Belarus', es: 'Belarús', en: 'Belarus' },
  { value: 'Belgium', es: 'Bélgica', en: 'Belgium' },
  { value: 'Belize', es: 'Belice', en: 'Belize' },
  { value: 'Benin', es: 'Benin', en: 'Benin' },
  { value: 'Bermuda', es: 'Bermuda', en: 'Bermuda' },
  { value: 'Bhutan', es: 'Bhután', en: 'Bhutan' },
  { value: 'Bolivia', es: 'Bolivia', en: 'Bolivia' },
  { value: 'Bonaire', es: 'Bonaire', en: 'Bonaire' },
  {
    value: 'Bosnia & Herzegovina',
    es: 'Bosnia y Herzegovina',
    en: 'Bosnia & Herzegovina',
  },
  { value: 'Botswana', es: 'Botswana', en: 'Botswana' },
  { value: 'Brazil', es: 'Brasil', en: 'Brazil' },
  { value: 'Brunei', es: 'Brunei', en: 'Brunei' },
  { value: 'Bulgaria', es: 'Bulgaria', en: 'Bulgaria' },
  { value: 'Burkina Faso', es: 'Burkina Faso', en: 'Burkina Faso' },
  { value: 'Burundi', es: 'Burundi', en: 'Burundi' },
  { value: 'Cape Verde', es: 'Cabo Verde', en: 'Cape Verde' },
  { value: 'Cambodia', es: 'Camboya', en: 'Cambodia' },
  { value: 'Cameroon', es: 'Camerún', en: 'Cameroon' },
  { value: 'Canada', es: 'Canadá', en: 'Canada' },
  { value: 'Chad', es: 'Chad', en: 'Chad' },
  { value: 'Czech Republic', es: 'Chequia', en: 'Czech Republic' },
  { value: 'Chile', es: 'Chile', en: 'Chile' },
  { value: 'China', es: 'China', en: 'China' },
  { value: 'Cyprus', es: 'Chipre', en: 'Cyprus' },
  {
    value: 'Vatican City State',
    es: 'Ciudad del Vaticano',
    en: 'Vatican City State',
  },
  { value: 'Colombia', es: 'Colombia', en: 'Colombia' },
  { value: 'Comoros', es: 'Comoras', en: 'Comoros' },
  { value: 'Congo', es: 'Congo', en: 'Congo' },
  { value: 'Korea North', es: 'Corea del Norte', en: 'Korea North' },
  { value: 'Korea South', es: 'Corea del Sur', en: 'Korea South' },
  { value: 'Costa Rica', es: 'Costa Rica', en: 'Costa Rica' },
  { value: 'Cote DIvoire', es: "Cote d'Ivoire", en: 'Cote DIvoire' },
  { value: 'Croatia', es: 'Croacia', en: 'Croatia' },
  { value: 'Cuba', es: 'Cuba', en: 'Cuba' },
  { value: 'Curaco', es: 'Curacao', en: 'Curaco' },
  { value: 'Denmark', es: 'Dinamarca', en: 'Denmark' },
  { value: 'Djibouti', es: 'Djibouti', en: 'Djibouti' },
  { value: 'Dominica', es: 'Dominica', en: 'Dominica' },
  { value: 'Ecuador', es: 'Ecuador', en: 'Ecuador' },
  { value: 'Egypt', es: 'Egipto', en: 'Egypt' },
  { value: 'El Salvador', es: 'El Salvador', en: 'El Salvador' },
  {
    value: 'United Arab Emirates',
    es: 'Emiratos Árabes Unidos',
    en: 'United Arab Emirates',
  },
  { value: 'Eritrea', es: 'Eritrea', en: 'Eritrea' },
  { value: 'Slovakia', es: 'Eslovaquia', en: 'Slovakia' },
  { value: 'Slovenia', es: 'Eslovenia', en: 'Slovenia' },
  { value: 'Spain', es: 'España', en: 'Spain' },
  {
    value: 'United States of America',
    es: 'Estados Unidos de América',
    en: 'United States of America',
  },
  { value: 'Estonia', es: 'Estonia', en: 'Estonia' },
  { value: 'Ethiopia', es: 'Etiopía', en: 'Ethiopia' },
  { value: 'Fiji', es: 'Fiji', en: 'Fiji' },
  { value: 'Phillipines', es: 'Filipinas', en: 'Phillipines' },
  { value: 'Finland', es: 'Finlandia', en: 'Finland' },
  { value: 'France', es: 'Francia', en: 'France' },
  { value: 'Gabon', es: 'Gabón', en: 'Gabon' },
  { value: 'Gambia', es: 'Gambia', en: 'Gambia' },
  { value: 'Georgia', es: 'Georgia', en: 'Georgia' },
  { value: 'Ghana', es: 'Ghana', en: 'Ghana' },
  { value: 'Gibraltar', es: 'Gibraltar', en: 'Gibraltar' },
  { value: 'Grenada', es: 'Granada', en: 'Grenada' },
  { value: 'Great Britain', es: 'Gran Bretaña', en: 'Great Britain' },
  { value: 'Greece', es: 'Grecia', en: 'Greece' },
  { value: 'Greenland', es: 'Groenlandia', en: 'Greenland' },
  { value: 'Guadeloupe', es: 'Guadalupe', en: 'Guadeloupe' },
  { value: 'Guam', es: 'Guam', en: 'Guam' },
  { value: 'Guatemala', es: 'Guatemala', en: 'Guatemala' },
  { value: 'French Guiana', es: 'Guayana Francesa', en: 'French Guiana' },
  { value: 'Guinea', es: 'Guinea', en: 'Guinea' },
  {
    value: 'Equatorial Guinea',
    es: 'Guinea Ecuatorial',
    en: 'Equatorial Guinea',
  },
  { value: 'Guyana', es: 'Guyana', en: 'Guyana' },
  { value: 'Haiti', es: 'Haití', en: 'Haiti' },
  { value: 'Hawaii', es: 'Hawaii', en: 'Hawaii' },
  { value: 'Honduras', es: 'Honduras', en: 'Honduras' },
  { value: 'Hong Kong', es: 'Hong Kong', en: 'Hong Kong' },
  { value: 'Hungary', es: 'Hungría', en: 'Hungary' },
  { value: 'India', es: 'India', en: 'India' },
  { value: 'Indonesia', es: 'Indonesia', en: 'Indonesia' },
  { value: 'Iran', es: 'Irán', en: 'Iran' },
  { value: 'Iraq', es: 'Iraq', en: 'Iraq' },
  { value: 'Ireland', es: 'Irlanda', en: 'Ireland' },
  { value: 'Iceland', es: 'Islandia', en: 'Iceland' },
  { value: 'Isle of Man', es: 'Isla de Man', en: 'Isle of Man' },
  { value: 'Cayman Islands', es: 'Islas Caimán', en: 'Cayman Islands' },
  { value: 'Cocos Island', es: 'Islas Cocos', en: 'Cocos Island' },
  { value: 'Cook Islands', es: 'Islas Cook', en: 'Cook Islands' },
  { value: 'Christmas Island', es: 'Islas de Navidad', en: 'Christmas Island' },
  { value: 'Channel Islands', es: 'Islas del Canal', en: 'Channel Islands' },
  { value: 'Faroe Islands', es: 'Islas Faroe', en: 'Faroe Islands' },
  { value: 'Falkland Islands', es: 'Islas Malvinas', en: 'Falkland Islands' },
  { value: 'Marshall Islands', es: 'Islas Marshall', en: 'Marshall Islands' },
  { value: 'Midway Islands', es: 'Islas Midway', en: 'Midway Islands' },
  { value: 'Norfolk Island', es: 'Islas Norfolk', en: 'Norfolk Island' },
  { value: 'Palau Island', es: 'Islas Palau', en: 'Palau Island' },
  { value: 'Pitcairn Island', es: 'Islas Pitcairn', en: 'Pitcairn Island' },
  { value: 'Solomon Islands', es: 'Islas Salomón', en: 'Solomon Islands' },
  {
    value: 'Turks & Caicos Is',
    es: 'Islas Turcas y Caicos',
    en: 'Turks & Caicos Is',
  },
  {
    value: 'Virgin Islands (Brit)',
    es: 'Islas Vírgenes Británicas',
    en: 'Virgin Islands (Brit)',
  },
  {
    value: 'Virgin Islands (USA)',
    es: 'Islas Vírgenes de los Estados Unidos',
    en: 'Virgin Islands (USA)',
  },
  { value: 'Wake Island', es: 'Isla Wake', en: 'Wake Island' },
  { value: 'Israel', es: 'Israel', en: 'Israel' },
  { value: 'Italy', es: 'Italia', en: 'Italy' },
  { value: 'Jamaica', es: 'Jamaica', en: 'Jamaica' },
  { value: 'Japan', es: 'Japón', en: 'Japan' },
  { value: 'Jordan', es: 'Jordania', en: 'Jordan' },
  { value: 'Kazakhstan', es: 'Kazajstán', en: 'Kazakhstan' },
  { value: 'Kenya', es: 'Kenia', en: 'Kenya' },
  { value: 'Kiribati', es: 'Kiribati', en: 'Kiribati' },
  { value: 'Kuwait', es: 'Kuwait', en: 'Kuwait' },
  { value: 'Kyrgyzstan', es: 'Kyrgyzstan', en: 'Kyrgyzstan' },
  { value: 'Laos', es: 'Laos', en: 'Laos' },
  { value: 'Lesotho', es: 'Lesoto', en: 'Lesotho' },
  { value: 'Latvia', es: 'Letonia', en: 'Latvia' },
  { value: 'Lebanon', es: 'Líbano', en: 'Lebanon' },
  { value: 'Liberia', es: 'Liberia', en: 'Liberia' },
  { value: 'Libya', es: 'Libia', en: 'Libya' },
  { value: 'Liechtenstein', es: 'Liechtenstein', en: 'Liechtenstein' },
  { value: 'Lithuania', es: 'Lituania', en: 'Lithuania' },
  { value: 'Luxembourg', es: 'Luxemburgo', en: 'Luxembourg' },
  { value: 'Macau', es: 'Macau', en: 'Macau' },
  { value: 'Macedonia', es: 'Macedonia', en: 'Macedonia' },
  { value: 'Madagascar', es: 'Madagascar', en: 'Madagascar' },
  { value: 'Malaysia', es: 'Malasia', en: 'Malaysia' },
  { value: 'Malawi', es: 'Malawi', en: 'Malawi' },
  { value: 'Maldives', es: 'Maldivas', en: 'Maldives' },
  { value: 'Mali', es: 'Mali', en: 'Mali' },
  { value: 'Malta', es: 'Malta', en: 'Malta' },
  { value: 'Morocco', es: 'Marruecos', en: 'Morocco' },
  { value: 'Martinique', es: 'Martinica', en: 'Martinique' },
  { value: 'Mauritania', es: 'Mauritania', en: 'Mauritania' },
  { value: 'Mauritius', es: 'Mauricio', en: 'Mauritius' },
  { value: 'Mayotte', es: 'Mayotte', en: 'Mayotte' },
  { value: 'Mexico', es: 'México', en: 'Mexico' },
  { value: 'Moldova', es: 'Moldavia', en: 'Moldova' },
  { value: 'Monaco', es: 'Mónaco', en: 'Monaco' },
  { value: 'Mongolia', es: 'Mongolia', en: 'Mongolia' },
  {
    value: 'Republic of Montenegro',
    es: 'Montenegro',
    en: 'Republic of Montenegro',
  },
  { value: 'Montserrat', es: 'Montserrat', en: 'Montserrat' },
  { value: 'Mozambique', es: 'Mozambique', en: 'Mozambique' },
  { value: 'Myanmar', es: 'Myanmar', en: 'Myanmar' },
  { value: 'Namibia', es: 'Namibia', en: 'Namibia' },
  { value: 'Nauru', es: 'Nauru', en: 'Nauru' },
  { value: 'Nepal', es: 'Nepal', en: 'Nepal' },
  { value: 'Nevis', es: 'Nevis', en: 'Nevis' },
  { value: 'New Caledonia', es: 'Nueva Caledonia', en: 'New Caledonia' },
  { value: 'New Zealand', es: 'Nueva Zelanda', en: 'New Zealand' },
  { value: 'Nicaragua', es: 'Nicaragua', en: 'Nicaragua' },
  { value: 'Niger', es: 'Níger', en: 'Niger' },
  { value: 'Nigeria', es: 'Nigeria', en: 'Nigeria' },
  { value: 'Niue', es: 'Niue', en: 'Niue' },
  { value: 'Norway', es: 'Noruega', en: 'Norway' },
  { value: 'Oman', es: 'Omán', en: 'Oman' },
  { value: 'Pakistan', es: 'Pakistán', en: 'Pakistan' },
  { value: 'Palestine', es: 'Palestina', en: 'Palestine' },
  { value: 'Netherlands', es: 'Países Bajos', en: 'Netherlands' },
  { value: 'Panama', es: 'Panamá', en: 'Panama' },
  {
    value: 'Papua New Guinea',
    es: 'Papua Nueva Guinea',
    en: 'Papua New Guinea',
  },
  { value: 'Paraguay', es: 'Paraguay', en: 'Paraguay' },
  { value: 'Peru', es: 'Perú', en: 'Peru' },
  { value: 'Poland', es: 'Polonia', en: 'Poland' },
  { value: 'Portugal', es: 'Portugal', en: 'Portugal' },
  {
    value: 'French Polynesia',
    es: 'Polinesia Francesa',
    en: 'French Polynesia',
  },
  { value: 'Puerto Rico', es: 'Puerto Rico', en: 'Puerto Rico' },
  { value: 'Qatar', es: 'Qatar', en: 'Qatar' },
  { value: 'United Kingdom', es: 'Reino Unido', en: 'United Kingdom' },
  {
    value: 'Central African Republic',
    es: 'República Centroafricana',
    en: 'Central African Republic',
  },
  {
    value: 'Dominican Republic',
    es: 'República Dominicana',
    en: 'Dominican Republic',
  },
  { value: 'Reunion', es: 'Reunión', en: 'Reunion' },
  { value: 'Romania', es: 'Rumanía', en: 'Romania' },
  { value: 'Russia', es: 'Rusia', en: 'Russia' },
  { value: 'Rwanda', es: 'Rwanda', en: 'Rwanda' },
  { value: 'Saipan', es: 'Saipán', en: 'Saipan' },
  { value: 'Samoa', es: 'Samoa', en: 'Samoa' },
  { value: 'Samoa American', es: 'Samoa Americana', en: 'Samoa American' },
  { value: 'St Barthelemy', es: 'San Bartolomé', en: 'St Barthelemy' },
  {
    value: 'St Kitts-Nevis',
    es: 'San Critóbal y Nieves',
    en: 'St Kitts-Nevis',
  },
  { value: 'St Eustatius', es: 'San Eustaquio', en: 'St Eustatius' },
  { value: 'San Marino', es: 'San Marino', en: 'San Marino' },
  { value: 'St Maarten', es: 'San Martín', en: 'St Maarten' },
  {
    value: 'St Pierre & Miquelon',
    es: 'San Pedro y Miquelón',
    en: 'St Pierre & Miquelon',
  },
  {
    value: 'St Vincent & Grenadines',
    es: 'San Vincente y las Granadinas',
    en: 'St Vincent & Grenadines',
  },
  { value: 'St Helena', es: 'Santa Helena', en: 'St Helena' },
  { value: 'St Lucia', es: 'Santa Lucía', en: 'St Lucia' },
  {
    value: 'Sao Tome & Principe',
    es: 'Sao Tome y Principe',
    en: 'Sao Tome & Principe',
  },
  { value: 'Senegal', es: 'Senegal', en: 'Senegal' },
  { value: 'Republic of Serbia', es: 'Serbia', en: 'Republic of Serbia' },
  { value: 'Seychelles', es: 'Seychelles', en: 'Seychelles' },
  { value: 'Sierra Leone', es: 'Sierra Leona', en: 'Sierra Leone' },
  { value: 'Singapore', es: 'Singapur', en: 'Singapore' },
  { value: 'Syria', es: 'Siria', en: 'Syria' },
  { value: 'Somalia', es: 'Somalia', en: 'Somalia' },
  { value: 'Sri Lanka', es: 'Sri Lanka', en: 'Sri Lanka' },
  { value: 'Swaziland', es: 'Suazilandia', en: 'Swaziland' },
  { value: 'South Africa', es: 'Sudáfrica', en: 'South Africa' },
  { value: 'Sudan', es: 'Sudán', en: 'Sudan' },
  { value: 'Sweden', es: 'Suecia', en: 'Sweden' },
  { value: 'Switzerland', es: 'Suiza', en: 'Switzerland' },
  { value: 'Suriname', es: 'Suriname', en: 'Suriname' },
  { value: 'Tahiti', es: 'Tahití', en: 'Tahiti' },
  { value: 'Thailand', es: 'Tailandia', en: 'Thailand' },
  { value: 'Taiwan', es: 'Taiwán', en: 'Taiwan' },
  { value: 'Tajikistan', es: 'Tajikistan', en: 'Tajikistan' },
  { value: 'Tanzania', es: 'Tanzania', en: 'Tanzania' },
  { value: 'East Timor', es: 'Timor del Este', en: 'East Timor' },
  { value: 'Togo', es: 'Togo', en: 'Togo' },
  { value: 'Tokelau', es: 'Tokelau', en: 'Tokelau' },
  { value: 'Tonga', es: 'Tonga', en: 'Tonga' },
  {
    value: 'Trinidad & Tobago',
    es: 'Trinidad y Tobago',
    en: 'Trinidad & Tobago',
  },
  { value: 'Tunisia', es: 'Tunisia', en: 'Tunisia' },
  { value: 'Turkey', es: 'Turquía', en: 'Turkey' },
  { value: 'Turkmenistan', es: 'Turkmenistán', en: 'Turkmenistan' },
  { value: 'Tuvalu', es: 'Tuvalu', en: 'Tuvalu' },
  { value: 'Ukraine', es: 'Ucraina', en: 'Ukraine' },
  { value: 'Uganda', es: 'Uganda', en: 'Uganda' },
  { value: 'Uruguay', es: 'Uruguay', en: 'Uruguay' },
  { value: 'Uzbekistan', es: 'Uzbekistán', en: 'Uzbekistan' },
  { value: 'Vanuatu', es: 'Vanuatu', en: 'Vanuatu' },
  { value: 'Venezuela', es: 'Venezuela', en: 'Venezuela' },
  { value: 'Vietnam', es: 'Vietnam', en: 'Vietnam' },
  {
    value: 'Wallis & Futana Is',
    es: 'Wallis y Futana',
    en: 'Wallis & Futana Is',
  },
  { value: 'Yemen', es: 'Yemen', en: 'Yemen' },
  { value: 'Zaire', es: 'Zaire', en: 'Zaire' },
  { value: 'Zambia', es: 'Zambia', en: 'Zambia' },
  { value: 'Zimbabwe', es: 'Zimbabwe', en: 'Zimbabwe' },
];

function padTo2Digits(num: number) {
  return num.toString().padStart(2, '0');
}

const getDataFechaConsentimiento = () => {
  let yourDate = new Date();
  return [
    padTo2Digits(yourDate.getDate()),
    padTo2Digits(yourDate.getMonth() + 1),
    yourDate.getFullYear(),
  ].join('-');
};

export default function ContactFormPardot(props: ContactFormProps) {
  const {
    distribuidor,
    card = false,
    title,
    header = settings.EMAIL_DEFAULT_HEADER,
    empresa = false,
    cultivos = false,
    actividad = false,
    provincia = false,
    telefono = false,
    ciudad = false,
    comentarios = false,
    reducedCargosList = false,
    infoComercialOblig = false,
    empresaOblig = true,
    GDPRTextVersion,
    documentos = false,
    link = 'https://go.pardot.com/l/939633/2021-09-08/fpjvt5',
    eventLabel = 'Enviar Solicitud',
    eventAction = 'Formulario de X',
    croptology = false,
  } = props;
  const [isDismissed, setIsDismissed] = useState(false);
  const [avisoLegal, setAvisolegal] = useState(false);
  const [getdocumento, setDocumento] = useState<any[]>([]);
  const [message, setMessage] = useState('');
  const [autorizacionNoticias, setAutorizacionNoticias] = useState(false);

  const { t } = useTranslation();

  const cultivosList = [
    { name: 'Banano', icon: <BananoIcon />, id: 'Banano' },
    { name: 'Berries', icon: <BerriesIcon />, id: 'Berries' },
    { name: 'Café', icon: <CafeIcon />, id: 'Café' },
    { name: 'Cítricos', icon: <CitricosIcon />, id: 'Cítricos' },
    { name: 'Extensivos', icon: <ExtensivoIcon />, id: 'Extensivos' },
    { name: 'Flores', icon: <FloresIcon />, id: 'Flores' },
    { name: 'Frutas', icon: <FrutasIcon />, id: 'Frutas' },
    { name: 'Frutos secos', icon: <FrutosSecosIcon />, id: 'Frutos secos' },
    { name: 'Hortalizas', icon: <HortalizaIcon />, id: 'Hortalizas' },
    { name: 'Olivo', icon: <OlivoIcon />, id: 'Olivo' },
    { name: 'Patata', icon: <PatataIcon />, id: 'Patata' },
    { name: 'Uva', icon: <Uva />, id: 'Uva' },
    { name: 'Fibra', icon: <Fibra />, id: 'Fibra' },
  ];

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    const formData = new FormData(e.currentTarget);
    let token = '';
    let info = Object.fromEntries(formData);
    let data = {
      token,
      link,
      info,
    };
    e.preventDefault();
    e.currentTarget.reset();
    grecaptcha.enterprise.ready(function () {
      grecaptcha.enterprise
        .execute(settings.google.BIOVERT_GOOGLE_RECAPTCHA_KEY, {
          action: 'submit',
        })
        .then(function (token: any) {
          setIsDismissed(true);
          data.token = token;
          fetch('/api/form-pardot', {
            method: 'post',
            body: JSON.stringify(data),
          })
            .then((response) => {
              if (response.status === 500) {
                //error handler
              } else {
                //all good

                setMessage('');
              }
            })
            .catch((err) => {
              console.log('Error: ', err);
            });
        });
    });
  };

  const getCountries = () => {
    const options = [];
    const orderedCountries: string[] = [];
    options.push(
      <option key="paises-default" defaultValue="" value="">
        {t('ContactForm.paises.defaultOpt')}
      </option>
    );
    if (i18n?.language == 'esp') {
      paises.map((pais) =>
        options.push(
          <option key={pais.es} value={pais.value}>
            {pais.es}
          </option>
        )
      );
    } else {
      // Object.entries(en.ContactForm.paises.nombres)
      //   .sort((a, b) => a[1].localeCompare(b[1]))
      //   // .sort((a, b) => (a[1] > b[1] ? 1 : -1))
      //   .forEach((entry, index) => {
      //     const [key, value] = entry;
      //     const result: any = key;
      //     options.push(
      //       <option
      //         key={value}
      //         value={`${es.ContactForm.paises.nombreFormulariosFinales[result]}`}
      //       >
      //         {t("ContactForm.paises.nombres." + key)}
      //       </option>
      //     );
      //   });
      paises
        .sort((a, b) => a.en.localeCompare(b.en))
        .map((pais) =>
          options.push(
            <option key={pais.en} value={pais.value}>
              {pais.en}
            </option>
          )
        );
    }

    if (i18n?.language == 'en') {
      orderedCountries.forEach((value) => {
        options.push(
          <option key={value} value={value}>
            {value}
          </option>
        );
      });
      orderedCountries.sort();
    }

    return options;
  };
  const handleMessageChange = (event: any) => {
    setMessage(event.target.value);
  };
  const getGDPRText = () => {
    switch (GDPRTextVersion) {
      case 'contacto':
        return t('ContactForm.newGDPR');
      case 'croptology':
        return t('ContactForm.GDPRCroptologyAlta');
      case 'distribuidor':
        return t('ContactForm.newGDPR');
      case 'producto':
        return t('ContactForm.newGDPR');
    }
  };

  return (
    <div className={classNames(styles.root)}>
      {!isDismissed ? (
        // <Formik initialValues={initialValues} onSubmit={handleSubmitForm} action="https://go.pardot.com/l/939633/2021-09-08/fpjvt5">
        <form
          // action={link}
          onSubmit={handleSubmit}
          method="POST"
          className={classNames({ [styles.card]: card })}
        >
          {title && <Text as="h2">{title}</Text>}
          {distribuidor && (
            <div className={styles.distribuidorcontainer}>
              <div className={styles.avatar}>
                <Image src={distribuidor.img} alt="" />
              </div>
              <Text as="h4">{distribuidor.nom}</Text>
              <TemplateText template="small">
                {distribuidor.carrec}
              </TemplateText>
            </div>
          )}
          <div className={styles.fields}>
            <div className={styles.fieldcontainer}>
              <label htmlFor="Nombre">{t('ContactForm.firstName')} *</label>
              <input
                required
                type="text"
                name="Nombre"
                id="Nombre"
                placeholder="Marc"
                maxLength={40}
                size={20}
              />
            </div>
            <div className={styles.fieldcontainer}>
              <label htmlFor="Apellidos"> {t('ContactForm.lastName')} *</label>
              <input
                required
                type="text"
                name="Apellidos"
                id="Apellidos"
                placeholder="García Perez"
                maxLength={80}
                size={20}

                //maxLength="80"
              />
            </div>
            {empresa && (
              <div className={styles.fieldcontainer}>
                <label htmlFor="Empresa">
                  {empresaOblig
                    ? t('ContactForm.company') + ' *'
                    : t('ContactForm.company')}
                </label>
                <input
                  required={empresaOblig ? true : false}
                  type="text"
                  name="Empresa"
                  id="Empresa"
                  placeholder={t('ContactForm.company') || ''}
                  maxLength={40}
                  size={20}
                />
              </div>
            )}
            {actividad && (
              <div className={styles.fieldcontainer}>
                <label htmlFor="Tipo de actividad">
                  {t('ContactForm.actividad.title')} *
                </label>
                <select
                  required
                  name="Tipo de actividad"
                  id="Tipo de actividad"
                  //as="select"
                >
                  <option key="actividad-default" defaultValue="" value="">
                    {t('ContactForm.actividad.defaultOpt')}
                  </option>
                  <option
                    key={es.ContactForm.actividad.opt1value}
                    value={es.ContactForm.actividad.opt1value}
                  >
                    {t('ContactForm.actividad.opt1')}
                  </option>
                  <option
                    key={es.ContactForm.actividad.opt2}
                    value={es.ContactForm.actividad.opt2}
                  >
                    {t('ContactForm.actividad.opt2')}
                  </option>
                  <option
                    key={es.ContactForm.actividad.opt3value}
                    value={es.ContactForm.actividad.opt3value}
                  >
                    {t('ContactForm.actividad.opt3')}
                  </option>
                  <option
                    key={es.ContactForm.actividad.opt4}
                    value={es.ContactForm.actividad.opt4}
                  >
                    {t('ContactForm.actividad.opt4')}
                  </option>
                  <option
                    key={es.ContactForm.actividad.opt5}
                    value={es.ContactForm.actividad.opt5}
                  >
                    {t('ContactForm.actividad.opt5')}
                  </option>
                  <option
                    key={es.ContactForm.actividad.opt6value}
                    value={es.ContactForm.actividad.opt6value}
                  >
                    {t('ContactForm.actividad.opt6')}
                  </option>
                  <option
                    key={es.ContactForm.actividad.opt7}
                    value={es.ContactForm.actividad.opt7}
                  >
                    {t('ContactForm.actividad.opt7')}
                  </option>
                  <option
                    key={es.ContactForm.actividad.opt8}
                    value={es.ContactForm.actividad.opt8}
                  >
                    {t('ContactForm.actividad.opt8')}
                  </option>
                  <option
                    key={es.ContactForm.actividad.opt9}
                    value={es.ContactForm.actividad.opt9}
                  >
                    {t('ContactForm.actividad.opt9')}
                  </option>
                  <option
                    key={es.ContactForm.actividad.opt10}
                    value={es.ContactForm.actividad.opt10}
                  >
                    {t('ContactForm.actividad.opt10')}
                  </option>
                </select>
              </div>
            )}
            <div className={styles.fieldcontainer}>
              <label htmlFor="Cargo">{t('ContactForm.cargo.title')} *</label>
              <select
                required
                name="Cargo"
                id="Cargo"
                //as="select"
              >
                <option key="cargo-default" defaultValue="" value="">
                  {t('ContactForm.cargo.defaultOpt')}
                </option>
                {!reducedCargosList && (
                  <>
                    <option
                      key={es.ContactForm.cargo.opt1}
                      value={es.ContactForm.cargo.opt1}
                    >
                      {t('ContactForm.cargo.opt1')}
                    </option>
                    <option
                      key={es.ContactForm.cargo.opt2}
                      value={es.ContactForm.cargo.opt2}
                    >
                      {t('ContactForm.cargo.opt2')}
                    </option>
                    <option
                      key={es.ContactForm.cargo.opt3}
                      value={es.ContactForm.cargo.opt3}
                    >
                      {t('ContactForm.cargo.opt3')}
                    </option>
                  </>
                )}
                <option
                  key={es.ContactForm.cargo.opt4}
                  value={es.ContactForm.cargo.opt4}
                >
                  {t('ContactForm.cargo.opt4')}
                </option>
                <option
                  key={es.ContactForm.cargo.opt5value}
                  value={es.ContactForm.cargo.opt5value}
                >
                  {t('ContactForm.cargo.opt5')}
                </option>
                <option
                  key={es.ContactForm.cargo.opt6value}
                  value={es.ContactForm.cargo.opt6value}
                >
                  {t('ContactForm.cargo.opt6')}
                </option>
                <option
                  key={es.ContactForm.cargo.opt7value}
                  value={es.ContactForm.cargo.opt7value}
                >
                  {t('ContactForm.cargo.opt7')}
                </option>
                <option
                  key={es.ContactForm.cargo.opt8}
                  value={es.ContactForm.cargo.opt8}
                >
                  {t('ContactForm.cargo.opt8')}
                </option>
                <option
                  key={es.ContactForm.cargo.opt9}
                  value={es.ContactForm.cargo.opt9}
                >
                  {t('ContactForm.cargo.opt9')}
                </option>
                <option
                  key={es.ContactForm.cargo.opt10value}
                  value={es.ContactForm.cargo.opt10value}
                >
                  {t('ContactForm.cargo.opt10')}
                </option>
                <option
                  key={es.ContactForm.cargo.opt11value}
                  value={es.ContactForm.cargo.opt11value}
                >
                  {t('ContactForm.cargo.opt11')}
                </option>
                <option
                  key={es.ContactForm.cargo.opt12}
                  value={es.ContactForm.cargo.opt12}
                >
                  {t('ContactForm.cargo.opt12')}
                </option>
                <option
                  key={es.ContactForm.cargo.opt13}
                  value={es.ContactForm.cargo.opt13}
                >
                  {t('ContactForm.cargo.opt13')}
                </option>
                {!reducedCargosList && (
                  <>
                    <option
                      key={es.ContactForm.cargo.opt14}
                      value={es.ContactForm.cargo.opt14}
                    >
                      {t('ContactForm.cargo.opt14')}
                    </option>
                    <option
                      key={es.ContactForm.cargo.opt15}
                      value={es.ContactForm.cargo.opt15}
                    >
                      {t('ContactForm.cargo.opt15')}
                    </option>
                  </>
                )}
                <option
                  key={es.ContactForm.cargo.opt16}
                  value={es.ContactForm.cargo.opt16}
                >
                  {t('ContactForm.cargo.opt16')}
                </option>
              </select>
            </div>
            <div className={styles.fieldcontainer}>
              <label htmlFor="email">{t('ContactForm.mail')} *</label>
              <input
                required
                type="email"
                name="email"
                id="email"
                placeholder="abc@gmail.com"
                maxLength={80}
                size={20}
              />
            </div>
            {telefono && (
              <div className={styles.fieldcontainer}>
                <label htmlFor="Teléfono">{t('ContactForm.phone')} *</label>
                <input
                  required
                  type="Teléfono"
                  id="Teléfono"
                  name="Teléfono"
                  placeholder="+34 628452190"
                  maxLength={80}
                />
              </div>
            )}
            {ciudad && (
              <div className={styles.fieldcontainer}>
                <label htmlFor="Ciudad">{t('ContactForm.city')} *</label>
                <input
                  required
                  type="Ciudad"
                  name="Ciudad"
                  id="Ciudad"
                  placeholder={t('ContactForm.city') || ''}
                  size={20}
                  maxLength={80}
                />
              </div>
            )}
            {provincia && (
              <div className={styles.fieldcontainer}>
                <label htmlFor="Territory">
                  {t('ContactForm.provincia')} *
                </label>
                <input
                  required
                  type="Territory"
                  name="Territory"
                  id="Territory"
                  size={20}
                  placeholder={t('ContactForm.provincia') || ''}
                  maxLength={80}
                />
              </div>
            )}
            <div className={styles.fieldcontainer}>
              <label htmlFor="País">{t('ContactForm.paises.title')} *</label>
              <select
                required
                name="País"
                id="País"
                //as="select"
              >
                {getCountries()}
              </select>
            </div>
            {documentos && (
              <>
                <div className={styles.fieldcontainer}>
                  <label className={styles.cultivoslabel}>
                    {t('ContactForm.documentos.title')}
                  </label>
                  <div className={styles.cultivos}>
                    <label htmlFor="Ficha técnica" className={styles.cultivo}>
                      <input
                        id="Ficha técnica"
                        type="checkbox"
                        name="Ficha técnica"
                        value="true"
                        onChange={(z) =>
                          z.target.checked === true
                            ? setDocumento((old) => [...old, z.target.id])
                            : setDocumento((old) =>
                                old.filter((w) => w !== z.target.id)
                              )
                        }
                      />
                      <p>{t('ContactForm.documentos.fichaTecnica')}</p>
                    </label>
                    <label
                      htmlFor="Ficha de seguridad"
                      className={styles.cultivo}
                    >
                      <input
                        id="Ficha de seguridad"
                        type="checkbox"
                        name="Ficha de seguridad"
                        value="true"
                        onChange={(z) =>
                          z.target.checked === true
                            ? setDocumento((old) => [...old, z.target.id])
                            : setDocumento((old) =>
                                old.filter((w) => w !== z.target.id)
                              )
                        }
                      />
                      <p>{t('ContactForm.documentos.fichaSeguridad')}</p>
                    </label>
                    <label
                      htmlFor="Certificación orgánica"
                      className={styles.cultivo}
                    >
                      <input
                        id="Certificación orgánica"
                        type="checkbox"
                        name="Certificación orgánica"
                        value="true"
                        onChange={(z) =>
                          z.target.checked === true
                            ? setDocumento((old) => [...old, z.target.id])
                            : setDocumento((old) =>
                                old.filter((w) => w !== z.target.id)
                              )
                        }
                      />
                      <p>{t('ContactForm.documentos.certificacion')}</p>
                    </label>
                  </div>
                </div>
              </>
            )}
            {cultivos && (
              <div className={styles.fieldcontainer}>
                <Text className={styles.cultivoslabel}>
                  {t('ContactForm.cultivos')}
                </Text>
                <div className={styles.cultivos}>
                  {cultivosList.map((item) => {
                    return (
                      <label
                        htmlFor={item.id}
                        key={`${t('ContactForm.cultivosList.' + item.name)}`}
                        className={styles.cultivo}
                      >
                        <input
                          type="checkbox"
                          id={item.id}
                          name={item.name}
                          value="true"
                          onChange={(x) => x.target.checked}
                        />
                        {item.icon}
                        <Text>
                          {t('ContactForm.cultivosList.' + item.name)}
                        </Text>
                      </label>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
          {comentarios && (
            <div className={styles.comentscontainer}>
              <label htmlFor="Comentarios">{t('ContactForm.comments')}</label>
              <textarea
                //as="textarea"
                name="Comentarios"
                placeholder={t('ContactForm.commentsPlaceholder') || ''}
                value={message}
                onChange={handleMessageChange}
                //rows="4"
              />
            </div>
          )}
          <div className={styles.comentscontainer}>
            <TemplateText template="micro">
              <br />
              <Trans>{getGDPRText()}</Trans>
              <Link
                legacyBehavior
                passHref
                href={MainRouter.privacidad.generatePath()}
              >
                <a target="_blank" rel="noreferrer">
                  <u>{t('ContactForm.politicaPrivacidadLink')}</u>.
                </a>
              </Link>
            </TemplateText>
          </div>
          <div className={styles.priv}>
            <label className={styles.textLabel}>
              <TemplateText template="micro">
                <input
                  type="checkbox"
                  name="GDPR"
                  id="GDPR"
                  value="true"
                  onChange={(e) => setAvisolegal(e.target.checked)}
                  required // Se vuelve obligatorio si croptology es true
                />
                {t('ContactForm.privacity')}
                <Link
                  legacyBehavior
                  passHref
                  href={MainRouter.privacidad.generatePath()}
                >
                  <a>
                    <u className={styles.separacionTexto}>
                      {t('ContactForm.privacityPrivacidad')}
                    </u>
                  </a>
                </Link>
              </TemplateText>
            </label>
          </div>
          {croptology ? null : (
            <div className={styles.priv}>
              <label className={styles.textLabel}>
                <TemplateText template="micro">
                  <input
                    type="checkbox"
                    name="NEWSLETTER"
                    id="NEWSLETTER"
                    value="true"
                    onChange={(e) => setAutorizacionNoticias(e.target.checked)}
                  />
                  {t('ContactForm.AutorizacionNoticias')}
                </TemplateText>
              </label>
            </div>
          )}

          <div className={styles.submitcontainer}>
            <input
              style={{ visibility: 'hidden' }}
              type="checkbox"
              id="Información comercial"
              name="Información comercial"
              value="true"
            />
            <select
              name="Idioma"
              id="Language"
              style={{ visibility: 'hidden' }}
            >
              <option value={i18n?.language.toUpperCase()}>
                {i18n?.language.toUpperCase() === 'ES'
                  ? 'Castellano'
                  : 'Inglés'}
              </option>
            </select>
            <input
              type="hidden"
              id="Fecha Consentimiento"
              name="Fecha Consentimiento"
              value={getDataFechaConsentimiento()}
            />

            {GDPRTextVersion === 'producto' ? (
              <input
                type="hidden"
                id="Comentarios"
                name="Comentarios"
                value={`${getDataFechaConsentimiento()}: COMENTARIOS ${message} ${getdocumento} ${title} `}
              />
            ) : null}

            <div>
              <button type="submit">
                <TemplateText template="strong">
                  {t('ContactForm.send')}
                </TemplateText>
              </button>
            </div>
          </div>
        </form>
      ) : (
        //</Formik>
        <div className={styles.dississedPanel}>
          {croptology ? (
            <div>
              <TemplateText template="strong">
                {t('ContactForm.successCroptology1')}
              </TemplateText>
              <TemplateText template="normal">
                {t('ContactForm.successCroptology2')}
              </TemplateText>
            </div>
          ) : (
            <div>
              <TemplateText template="strong">
                {t('ContactForm.success1')}
              </TemplateText>
              <TemplateText template="normal">
                {t('ContactForm.success2')}
              </TemplateText>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
